
document.addEventListener('turbo:load', function() {
  // Make sure jQuery is available
  if (typeof $ !== 'undefined') {
    // show password input value
    $("#password-addon").on('click', function () {
      if ($(this).siblings('input').length > 0) {
        $(this).siblings('input').attr('type') == "password" ? $(this).siblings('input').attr('type', 'input') : $(this).siblings('input').attr('type', 'password');
      }
    });

    $("#password-addon2").on('click', function () {
      if ($(this).siblings('input').length > 0) {
        $(this).siblings('input').attr('type') == "password" ? $(this).siblings('input').attr('type', 'input') : $(this).siblings('input').attr('type', 'password');
      }
    });
  }
});